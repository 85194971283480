angular.module('fingerink')
    .directive('avanzadaTextarea', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-textarea.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            }
        };
    });
